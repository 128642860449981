import { useEffect } from "react";
import { isAndroid, isIOS } from "react-device-detect";

export const CivinityLtResidential = () => {
  useEffect(() => {
    const link = isAndroid
      ? "https://play.google.com/store/apps/details?id=com.partly&referrer=utm_source%3DPaper%2520banners%2520in%2520residential%2520buildings%26utm_campaign%3DCivinityLtResidential"
      : isIOS
      ? "https://apps.apple.com/app/apple-store/id1449245749?pt=119551563&ct=Civinity%20Lt%20Residential&mt=8"
      : "https://hoomtask.com";

    // We want to prevent the user from coming back to this route after coming here, because it will
    window.location.replace(link);
  }, []);

  return null;
};
