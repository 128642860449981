import { colors } from '../../styles/colors';
import styled from 'styled-components';
import BeatLoader from 'react-spinners/BeatLoader';

type Props = {
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset' | undefined;
  text: string;
  disabled?: boolean;
  loading?: boolean;
};

export const Button = ({ onClick, disabled = false, type, text, loading = false }: Props) => {
  return (
    <Container type={type || undefined} onClick={onClick} disabled={disabled || loading}>
      {!loading ? <Text>{text}</Text> : null}
      <BeatLoader color={colors.WHITE} loading={loading} size={18} />
    </Container>
  );
};

const Container = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  border-radius: 28px;
  border: none;
  padding: 0rem 2rem;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease-in-out, transform 150ms ease;
  color: ${colors.WHITE};
  background: ${colors.P2};
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: 'Plus Jakarta Sans', sans-serif;

  &:hover,
  &:focus {
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: ${colors.G1};
    color: ${colors.BLACK};
    cursor: not-allowed;
  }
`;

const Text = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
`;
