import './style.css';
import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from '../../../../styles/colors';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { icons } from '../../../../utils/icons';
import { CaretDown } from '../Select';

type Props = Types.InputProps;

export const DateField = ({ value, name, error, onChange, onBlur, placeholder }: Props) => {
  // https://github.com/i18next/react-i18next/issues/1417
  // @ts-ignore
  let { t } = useTranslation('translations');
  const [date, setDate] = useState<Date | null | undefined>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const inputRef = useRef<DatePicker>(null);

  useEffect(() => {
    if (value === '') setDate(null);
  }, [value]);

  const onSelect = (date: Date, event: React.SyntheticEvent<any, Event> | undefined) => {
    setDate(date);
    onChange(date?.toDateString());
  };

  const handleDateChange = (date: Date | null, event: React.SyntheticEvent<any, Event> | undefined) => {
    setDate(date);
    onChange(date?.toDateString());
  };

  return (
    <Wrapper>
      <DatePicker
        ref={inputRef}
        minDate={new Date()}
        startDate={new Date()}
        name={name}
        selected={date}
        onSelect={onSelect}
        onBlur={onBlur}
        onChange={handleDateChange}
        onCalendarClose={() => setIsOpen(false)}
        onCalendarOpen={() => setIsOpen(true)}
        className={[!!error ? 'error' : ''].join(' ')}
      />
      <Placeholder
        onClick={(e) => {
          e.preventDefault();
          inputRef.current?.setOpen(true);
        }}
        className={[!!error ? 'error' : '', isOpen ? 'open' : ''].join(' ')}
      >
        <Content>
          <Icon src={icons.calendar_blank} />
          {!date && <Text>{t('call_center.form.date_placeholder')}</Text>}
        </Content>
        {isOpen ? <CaretDown stroke={colors.P1} /> : <CaretDown />}
      </Placeholder>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;

  input {
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid ${colors.G1};
    border-radius: 8px;
    padding: 0 16px 0 56px;
    overflow: hidden;
    font-family: 'Plus Jakarta Sans';

    &:hover {
      border: 1px solid ${colors.G4};
      outline: none;
    }

    &:focus {
      border: 1px solid ${colors.P1};
      outline: none;
    }

    &:hover + &.error {
      border: 1px solid ${colors.S5};
    }

    &:focus + &.error {
      border: 2px solid ${colors.S5};
      outline: none;
    }
    &:active + &.error {
      border: 1px solid ${colors.S5};
      outline: none;
    }

    &.error {
      color: ${colors.S5};
      border: 1px solid ${colors.S5};
    }
  }
`;

const Placeholder = styled.button`
  position: absolute;
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 0 16px;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  background: transparent;
  border: 1px solid ${colors.G1};
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;

  &.open {
    border: 1px solid ${colors.P1};

    &:hover {
      border: 1px solid ${colors.P1};
    }
  }

  &:hover {
    border: 1px solid ${colors.G4};
    outeline: none;
  }

  &:focus {
    border: 1px solid ${colors.P1};
    outline: none;
  }

  &:hover + &.error {
    border: 1px solid ${colors.S5};
  }

  &:focus + &.error {
    border: 2px solid ${colors.S5};
    outline: none;
  }
  &:active + &.error {
    border: 2px solid ${colors.S5};
    outline: none;
  }

  &.error {
    color: ${colors.S5};
    border: 1px solid ${colors.S5};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
`;

const Icon = styled.img`
  margin: 0 16px 0 0;
`;

const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.G4};
  font-family: 'Plus Jakarta Sans', sans-serif;
`;
