import styled from "styled-components";
import { Modal } from "../modal";
import { colors } from "../../styles/colors";
import { useTranslation } from "react-i18next";
import { ReactComponent as SuccessLogo } from "../../assets/icons/success.svg";

type Props = {
  show: boolean;
  setShow?: (show: boolean) => void;
};

export const SuccessModal = ({ show, setShow }: Props) => {
  // https://github.com/i18next/react-i18next/issues/1417
  // @ts-ignore
  let { t } = useTranslation("translations");

  return (
    <Modal show={show} setShow={setShow}>
      <Container>
        <SuccessLogo height={256} />
        <Title>{t("call_center.success_modal.title")}</Title>
        <SecondaryTitle>
          {t("call_center.success_modal.secondary_title")}
        </SecondaryTitle>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 25px;
  line-height: 28px;
  text-align: center;

  margin: 16px 0 8px 0;
`;

const SecondaryTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  color: ${colors.G4};
  margin: 0 0 32px 0;
`;
