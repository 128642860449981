import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga4";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { BodyWrapper, Header, Footer } from "./components";
import {
  Home,
  PrivacyPolicy,
  CookiePolicy,
  TermsConditions,
  CallCenterRequest,
  Article,
  Get,
  Senukai,
  Kruza,
  CivinityLtResidential,
} from "./routes";
import "./index.scss"; // Tell webpack that Button.js uses these styles
import i18next from "i18next";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import RefLangBlock from "./components/RefLangComponent";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";

ReactGA.initialize([
  {
    trackingId: "G-530MYTVF3R",
  },
]);

ReactGA.send({ hitType: "pageview", page: window.location.pathname });

const queryClient = new QueryClient();

export const App = () => {
  if (!i18next.language) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <RefLangBlock />
      <BodyWrapper>
        <BrowserRouter
          basename={i18next.language !== "en" ? `/${i18next.language}` : ""}
        >
          <meta itemProp="name" content="HoomTask" />
          <meta itemProp="url" content="https://hoomtask.com" />

          <Header />

          <div
            itemScope
            itemProp="mainEntity"
            itemType="http://schema.org/Webpage"
          >
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="request" element={<CallCenterRequest />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="cookie-policy" element={<CookiePolicy />} />
              <Route path="terms-of-service" element={<TermsConditions />} />
              <Route path="article" element={<Article />} />
              <Route path="get/*" element={<Get />} />
              <Route path="k-senukai-latvia-in-store/*" element={<Senukai />} />
              <Route path="kruza-in-store/*" element={<Kruza />} />
              <Route
                path="CivinityLtResidential/*"
                element={<CivinityLtResidential />}
              />
            </Routes>
          </div>

          <Footer />
        </BrowserRouter>
      </BodyWrapper>
      <ToastContainer />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
