import { forwardRef } from 'react';
import styled from 'styled-components';
import { colors } from '../../../styles/colors';

type InputProps = { leftIcon: boolean };

type Props = Types.InputProps & { icon?: string };

export const Text = forwardRef<HTMLInputElement, Props>(
  ({ value, icon, name, error, onChange, onBlur, placeholder }, ref) => {
    return (
      <Wrapper>
        <Input
          ref={ref}
          type="text"
          value={value}
          name={name}
          leftIcon={!!icon}
          className={error ? 'error' : undefined}
          placeholder={placeholder ? placeholder : undefined}
          onChange={onChange}
          onBlur={onBlur}
        />
        {icon && <Icon src={icon} />}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 48px;
`;
const Icon = styled.img`
  position: absolute;
  height: 24px;
  width: 24px;
  left: 16px;
`;
const Input = styled.input<InputProps>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${colors.G1};
  border-radius: 8px;
  padding: ${(props) => (props.leftIcon ? '0 16px 0 56px' : '0 16px')};
  overflow: hidden;
  font-family: 'Plus Jakarta Sans';

  &:hover {
    border: 1px solid ${colors.G4};
    outline: none;
  }

  &:focus {
    border: 1px solid ${colors.P1};
    outline: none;
  }

  &:active {
    transform: scale(0.99);
  }

  &:hover + .error {
    border: 1px solid ${colors.S5};
  }

  &:focus + .error {
    outline: 2px solid ${colors.S5};
  }

  .error {
    color: ${colors.S5};
  }
`;
