import styled from "styled-components";
import { icons } from "../../utils/icons";
import { colors } from "../../styles/colors";

type Props = {
  text: string;
  image?: string;
};

export const FormHeader = ({ image, text }: Props) => {
  return (
    <>
      <Container>
        <Image src={image ? image : icons.partner_placeholder} />
        <Text>{text}</Text>
      </Container>
      <HorizontalLine />
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;
const Image = styled.img`
  width: 96px;
  height: 96px;
  margin-right: 24px;
  border-radius: 8px;
`;
const Text = styled.div`
  color: ${colors.BLACK};
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  font-family: "Plus Jakarta Sans";
`;
const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background: ${colors.G1};
  margin: 24px 0 16px;
`;
