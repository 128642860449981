import { Helmet } from "react-helmet";
import styled from "styled-components";
import { tablet } from "../styles/breakpoints";
import { Container, H5, Paragraph, H2 } from "../components";
import { grey, primary } from "../styles/colors";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useTranslation } from "react-i18next";

export const PrivacyPolicy = () => {
  // https://github.com/i18next/react-i18next/issues/1417
  // @ts-ignore
  const { t } = useTranslation("translations");

  return (
    <>
      <Helmet>
        <title>HoomTask {t("privacy_policy.title")}</title>
      </Helmet>

      <Wrapper>
        <Container padding="3rem 2rem">
          <PageTitle>
            <H2>{t("privacy_policy.title")}</H2>
          </PageTitle>
          <StyledTabs>
            <StyledTabList>
              <StyledTab>{t("privacy_policy.customers.title")}</StyledTab>
              <StyledTab>
                {t("privacy_policy.service_providers.title")}
              </StyledTab>
            </StyledTabList>

            <StyledTabPanel>
              <Content>
                <ContentText>
                  <ContentBlock>
                    <Paragraph>{t("privacy_policy.customers.intro")}</Paragraph>
                  </ContentBlock>
                  <ContentBlock>
                    <Heading>
                      <H5>1. {t("privacy_policy.customers.heading_1")}</H5>
                    </Heading>
                    <BulletPoints>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_1_bullet_1")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_1_bullet_2")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_1_bullet_3")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_1_bullet_4")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_1_bullet_5")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_1_bullet_6")}
                        </Paragraph>
                      </BulletPoint>
                    </BulletPoints>
                    <br></br> <br></br>
                    <Paragraph>
                      {t("privacy_policy.customers.heading_1_text")}
                    </Paragraph>
                    <BulletPoints>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.customers.heading_1_text_bullet_1"
                          )}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.customers.heading_1_text_bullet_2"
                          )}
                        </Paragraph>
                      </BulletPoint>
                    </BulletPoints>
                  </ContentBlock>
                  <ContentBlock>
                    <Heading>
                      <H5>2. {t("privacy_policy.customers.heading_2")}</H5>
                    </Heading>
                    <BulletPoints>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_2_bullet_1")}
                        </Paragraph>
                        <ul>
                          <li>
                            <Paragraph>
                              {t(
                                "privacy_policy.customers.heading_2_bullet_1_bullet_1"
                              )}
                            </Paragraph>
                          </li>
                          <li>
                            <Paragraph>
                              {t(
                                "privacy_policy.customers.heading_2_bullet_1_bullet_2"
                              )}
                            </Paragraph>
                          </li>
                          <li>
                            <Paragraph>
                              {t(
                                "privacy_policy.customers.heading_2_bullet_1_bullet_3"
                              )}
                            </Paragraph>
                          </li>
                        </ul>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_2_bullet_2")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_2_bullet_3")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_2_bullet_4")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_2_bullet_5")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_2_bullet_6")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_2_bullet_7")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_2_bullet_8")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_2_bullet_9")}
                        </Paragraph>
                      </BulletPoint>
                    </BulletPoints>
                  </ContentBlock>
                  <ContentBlock>
                    <Heading>
                      <H5>3. {t("privacy_policy.customers.heading_3")}</H5>
                    </Heading>
                    <BulletPoints>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_3_bullet_1")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_3_bullet_2")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_3_bullet_3")}
                        </Paragraph>
                      </BulletPoint>
                    </BulletPoints>
                  </ContentBlock>
                  <ContentBlock>
                    <Heading>
                      <H5>4. {t("privacy_policy.customers.heading_4")}</H5>
                    </Heading>
                    <BulletPoints>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_4_bullet_1")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_4_bullet_2")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_4_bullet_3")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_4_bullet_4")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_4_bullet_5")}
                        </Paragraph>
                      </BulletPoint>
                    </BulletPoints>
                  </ContentBlock>
                  <ContentBlock>
                    <Heading>
                      <H5>5. {t("privacy_policy.customers.heading_5")}</H5>
                    </Heading>
                    <BulletPoints>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_5_bullet_1")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_5_bullet_2")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_5_bullet_3")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_5_bullet_4")}
                        </Paragraph>
                      </BulletPoint>
                    </BulletPoints>
                  </ContentBlock>
                  <ContentBlock>
                    <Heading>
                      <H5>6. {t("privacy_policy.customers.heading_6")}</H5>
                    </Heading>
                    <BulletPoints>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_6_bullet_1")}
                        </Paragraph>
                      </BulletPoint>
                    </BulletPoints>
                  </ContentBlock>
                  <ContentBlock>
                    <Heading>
                      <H5>7. {t("privacy_policy.customers.heading_7")}</H5>
                    </Heading>
                    <BulletPoints>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_7_bullet_1")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_7_bullet_2")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_7_bullet_3")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_7_bullet_4")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_7_bullet_5")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_7_bullet_6")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_7_bullet_7")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_7_bullet_8")}
                        </Paragraph>
                      </BulletPoint>
                    </BulletPoints>
                  </ContentBlock>
                  <ContentBlock>
                    <Heading>
                      <H5>9. {t("privacy_policy.customers.heading_8")}</H5>
                    </Heading>
                    <BulletPoints>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_8_bullet_1")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_8_bullet_2")}
                        </Paragraph>
                      </BulletPoint>
                    </BulletPoints>
                  </ContentBlock>
                  <ContentBlock>
                    <Heading>
                      <H5>10. {t("privacy_policy.customers.heading_9")}</H5>
                    </Heading>
                    <BulletPoints>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_9_bullet_1")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_9_bullet_2")}
                        </Paragraph>
                      </BulletPoint>
                    </BulletPoints>
                  </ContentBlock>
                  <ContentBlock>
                    <Heading>
                      <H5>11. {t("privacy_policy.customers.heading_10")}</H5>
                    </Heading>
                    <BulletPoints>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_10_bullet_1")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_10_bullet_2")}
                        </Paragraph>
                      </BulletPoint>
                    </BulletPoints>
                  </ContentBlock>
                  <ContentBlock>
                    <Heading>
                      <H5>11. {t("privacy_policy.customers.heading_11")}</H5>
                    </Heading>
                    <BulletPoints>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_11_bullet_1")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_11_bullet_2")}
                        </Paragraph>
                      </BulletPoint>
                    </BulletPoints>
                  </ContentBlock>
                </ContentText>
              </Content>
            </StyledTabPanel>
            <StyledTabPanel>
              <Content>
                <ContentText>
                  <ContentBlock>
                    <Paragraph>
                      {t("privacy_policy.service_providers.intro")}
                    </Paragraph>
                  </ContentBlock>
                  <ContentBlock>
                    <Heading>
                      <H5>
                        1. {t("privacy_policy.service_providers.heading_1")}
                      </H5>
                    </Heading>
                    <BulletPoints>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_1_bullet_1"
                          )}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_1_bullet_2"
                          )}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_1_bullet_3"
                          )}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_1_bullet_4"
                          )}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_1_bullet_5"
                          )}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_1_bullet_6"
                          )}
                        </Paragraph>
                      </BulletPoint>
                    </BulletPoints>
                    <br></br> <br></br>
                    <Paragraph>
                      {t("privacy_policy.service_providers.heading_1_text")}
                    </Paragraph>
                  </ContentBlock>
                  <ContentBlock>
                    <Heading>
                      <H5>
                        2. {t("privacy_policy.service_providers.heading_2")}
                      </H5>
                    </Heading>
                    <BulletPoints>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_2_bullet_1"
                          )}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_2_bullet_2"
                          )}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_2_bullet_3"
                          )}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_2_bullet_4"
                          )}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_2_bullet_5"
                          )}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_2_bullet_6"
                          )}
                        </Paragraph>
                      </BulletPoint>
                    </BulletPoints>
                  </ContentBlock>
                  <ContentBlock>
                    <Heading>
                      <H5>
                        3. {t("privacy_policy.service_providers.heading_3")}
                      </H5>
                    </Heading>
                    <BulletPoints>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_3_bullet_1"
                          )}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_3_bullet_2"
                          )}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_3_bullet_3"
                          )}
                        </Paragraph>
                      </BulletPoint>
                    </BulletPoints>
                  </ContentBlock>
                  <ContentBlock>
                    <Heading>
                      <H5>
                        4. {t("privacy_policy.service_providers.heading_4")}
                      </H5>
                    </Heading>
                    <BulletPoints>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_4_bullet_1"
                          )}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_4_bullet_2"
                          )}
                        </Paragraph>
                      </BulletPoint>
                    </BulletPoints>
                  </ContentBlock>
                  <ContentBlock>
                    <Heading>
                      <H5>
                        5. {t("privacy_policy.service_providers.heading_5")}
                      </H5>
                    </Heading>
                    <BulletPoints>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_5_bullet_1"
                          )}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_5_bullet_2"
                          )}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_5_bullet_3"
                          )}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_5_bullet_4"
                          )}
                        </Paragraph>
                      </BulletPoint>
                    </BulletPoints>
                  </ContentBlock>
                  <ContentBlock>
                    <Heading>
                      <H5>
                        6. {t("privacy_policy.service_providers.heading_6")}
                      </H5>
                    </Heading>
                    <BulletPoints>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_6_bullet_1"
                          )}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_6_bullet_2"
                          )}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_6_bullet_3"
                          )}
                        </Paragraph>
                      </BulletPoint>
                    </BulletPoints>
                  </ContentBlock>
                  <ContentBlock>
                    <Heading>
                      <H5>
                        7. {t("privacy_policy.service_providers.heading_7")}
                      </H5>
                    </Heading>
                    <BulletPoints>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_7_bullet_1"
                          )}
                        </Paragraph>
                      </BulletPoint>
                    </BulletPoints>
                  </ContentBlock>
                  <ContentBlock>
                    <Heading>
                      <H5>
                        9. {t("privacy_policy.service_providers.heading_8")}
                      </H5>
                    </Heading>
                    <BulletPoints>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_8_bullet_1"
                          )}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_8_bullet_2"
                          )}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_8_bullet_3"
                          )}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_8_bullet_4"
                          )}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_8_bullet_5"
                          )}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_8_bullet_6"
                          )}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_8_bullet_7"
                          )}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_8_bullet_8"
                          )}
                        </Paragraph>
                      </BulletPoint>
                    </BulletPoints>
                  </ContentBlock>
                  <ContentBlock>
                    <Heading>
                      <H5>
                        10. {t("privacy_policy.service_providers.heading_9")}
                      </H5>
                    </Heading>
                    <BulletPoints>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_9_bullet_1"
                          )}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_9_bullet_2"
                          )}
                        </Paragraph>
                      </BulletPoint>
                    </BulletPoints>
                  </ContentBlock>
                  <ContentBlock>
                    <Heading>
                      <H5>
                        11. {t("privacy_policy.service_providers.heading_10")}
                      </H5>
                    </Heading>
                    <BulletPoints>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_10_bullet_1")}
                        </Paragraph>
                      </BulletPoint>
                    </BulletPoints>
                  </ContentBlock>
                  <ContentBlock>
                    <Heading>
                      <H5>
                        11. {t("privacy_policy.service_providers.heading_11")}
                      </H5>
                    </Heading>
                    <BulletPoints>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_11_bullet_1")}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t("privacy_policy.customers.heading_11_bullet_2")}
                        </Paragraph>
                      </BulletPoint>
                    </BulletPoints>
                  </ContentBlock>
                  <ContentBlock>
                    <Heading>
                      <H5>
                        11. {t("privacy_policy.service_providers.heading_12")}
                      </H5>
                    </Heading>
                    <BulletPoints>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_12_bullet_1"
                          )}
                        </Paragraph>
                      </BulletPoint>
                      <BulletPoint>
                        <Paragraph>
                          {t(
                            "privacy_policy.service_providers.heading_12_bullet_2"
                          )}
                        </Paragraph>
                      </BulletPoint>
                    </BulletPoints>
                  </ContentBlock>
                </ContentText>
              </Content>
            </StyledTabPanel>
          </StyledTabs>
        </Container>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;
const StyledTabs = styled(Tabs)`
  -webkit-tap-highlight-color: transparent;
  width: 100%;
`;
const StyledTabList = styled(TabList)`
  display: flex;
  margin: 0;
  list-style: none;
  padding: 0;
`;
const StyledTab = styled(Tab)`
  font-size: 16px;
  line-height: 24px;
  color: #acacac;
  font-weight: 500;
  padding: 0 16px 8px;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: ${grey};
  }
  &.react-tabs__tab--selected {
    color: ${primary};
    font-weight: 600;
    &:after {
      background: ${primary};
      height: 2px;
    }
  }
`;
const StyledTabPanel = styled(TabPanel)`
  position: relative;
`;
const PageTitle = styled.div`
  margin: 0 0 2.5rem;
`;
const Heading = styled.div`
  margin: 0 0 32px;
`;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 2.5rem 0 0;
  border-bottom: 1px solid ${grey};
  padding: 0 0 2rem;
`;
const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 32px;
  max-width: 75%;

  @media ${tablet} {
    max-width: unset;
  }
`;
const ContentText = styled.div`
  position: relative;
`;

const BulletPoints = styled.ul`
  padding: 0rem 0rem 0rem 1rem;
  margin: 0;
`;
const BulletPoint = styled.li`
  position: relative;
  padding: 0.5rem 0;
`;
