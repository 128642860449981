import { colors } from '../../../styles/colors';
import { useState, forwardRef } from 'react';
import styled from 'styled-components';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

type Props = Types.InputProps & {
  type?: Types.InputTextType;
  label: string;
};

export const MaterialText = forwardRef<HTMLInputElement, Props>(
  ({ type, value, error, label, onChange, onBlur, name }, ref) => {
    const [showPassword, setShowPassword] = useState(false);

    const toggleShowPassword = () => {
      setShowPassword(!showPassword);
    };

    return (
      <Container>
        <InputContainer>
          <input
            ref={ref}
            type={showPassword ? 'text' : type}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            className={[error && 'error'].join(' ')}
            // required is required for styling purposes (by css :valid pseudo-class)
            required
          />
          <label htmlFor={name}>{label}</label>
          {type === 'password' && (
            <EyeButton onClick={toggleShowPassword}>
              {showPassword ? (
                <AiOutlineEyeInvisible size={20} color={colors.G2} />
              ) : (
                <AiOutlineEye size={20} color={colors.G2} />
              )}
            </EyeButton>
          )}
        </InputContainer>
        <Error>{error}</Error>
      </Container>
    );
  },
);

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 0 16px 0;
`;

const InputContainer = styled.div`
  position: relative;
  height: 48px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  input {
    position: absolute;
    height: 48px;
    width: 100%;
    outline: 0;
    font-size: 16px;
    border: 1px solid ${colors.G1};
    padding: 0 16px;
    border-radius: 8px;
    font-family: 'Plus Jakarta Sans', sans-serif;

    &:hover,
    &:focus {
      border: 1px solid ${colors.P2};
    }
  }

  input.error {
    border: 2px solid ${colors.S5};
  }

  label {
    position: absolute;
    left: 16px;
    color: ${colors.G4};
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    transition: all 0.2s ease;
    pointer-events: none;
    font-family: 'Plus Jakarta Sans', sans-serif;
  }

  input:focus + label,
  input:valid + label {
    transform: translate(-4px, -150%);
    font-weight: 500;
    font-size: 10px;
    padding: 0 4px;
    background: ${colors.WHITE};
    z-index: 100;
  }
`;

const EyeButton = styled.button`
  position: absolute;
  height: 100%;
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: none;
`;

const Error = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.S5};
  margin: 4px 0 0 0;
`;
