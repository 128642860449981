import styled from "styled-components";
import { colors } from "../../styles/colors";

export const BodyWrapper = styled.div`
  position: relative;
  min-height: 100vh;
  font-size: 14px;
  font-weight: 400;
  color: ${colors.BLACK};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: ${colors.WHITE};
  font-family: "Plus Jakarta Sans", sans-serif;
`;
