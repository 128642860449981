import styled from 'styled-components';
import { Modal } from '../modal';
import { Button } from '../callCenter';
import { colors } from '../../styles/colors';
import { Input } from '../input/';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../../assets/icons/hoomtask_logo.svg';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useStore } from '../../hooks/useStore';
import Environment from '../../environment';

type FormValues = {
  email: string;
  password: string;
};

type Props = {
  show: boolean;
};

export const SignInModal = ({ show }: Props) => {
  // https://github.com/i18next/react-i18next/issues/1417
  // @ts-ignore
  let { t } = useTranslation('translations');
  const setUserData = useStore((state) => state.setUserData);

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<FormValues>({ mode: 'onChange' });

  const signIn = ({ email, password }: Types.Credentials) =>
    axios.post<Types.UserData>(`${Environment.apiUrl}/auth/login`, {
      email,
      password,
    });

  const userSignInMutation = useMutation(signIn, {
    onSuccess: (response) => {
      setUserData(response.data);
    },
    onError: (error) => {
      toast.error(t('call_center.validation.bad_credentials'), {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
  });

  const submitForm = handleSubmit(({ email, password }) => {
    userSignInMutation.mutate({ email, password });
  });

  return (
    <Modal show={show}>
      <Container>
        <Logo height={32} />
        <Title>{t('call_center.sign_in_modal.title')}</Title>
        <SecondaryTitle>{t('call_center.sign_in_modal.secondary_title')}</SecondaryTitle>
        <Form onSubmit={submitForm}>
          <Input
            type="text"
            styling="material"
            error={errors.email?.message}
            label={t('call_center.form.email')}
            {...register('email', {
              required: t('call_center.validation.required'),
              minLength: {
                value: 6,
                message: t('call_center.validation.min_length'),
              },
              maxLength: {
                value: 128,
                message: t('call_center.validation.max_length'),
              },
              pattern: {
                value: /^\S+@\S+\.+\S+$/i,
                message: t('call_center.validation.invalid_email'),
              },
            })}
          />
          <Input
            type="text"
            textType="password"
            styling="material"
            error={errors.password?.message}
            label={t('call_center.form.password')}
            {...register('password', {
              required: t('call_center.validation.required'),
              minLength: {
                value: 6,
                message: t('call_center.validation.min_length'),
              },
              maxLength: {
                value: 128,
                message: t('call_center.validation.max_length'),
              },
            })}
          />
          <Button
            type="submit"
            disabled={!isValid}
            text={t('call_center.form.continue')}
            loading={userSignInMutation.isLoading}
          />
        </Form>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Form = styled.form`
  width: 100%;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;

  margin: 16px 0 8px 0;
`;

const SecondaryTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;

  color: ${colors.G4};
  margin: 0 0 32px 0;
`;
