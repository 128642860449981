import styled from "styled-components";
import { primary, black, white, darkGrey, grey } from "../../styles/colors";

type WrapperProps = {
  margin?: string;
  position?: string;
};

type Props = {
  onClick: () => void;
  label: string;
  disabled?: boolean;
  type?: "button" | "reset" | "submit";
  className?: string;
  icon?: string;
  margin?: string;
  position?: string;
};

export const Button = ({
  onClick,
  label,
  disabled = false,
  type = "button",
  className,
  icon,
  margin,
  position,
}: Props) => {
  return (
    <Wrapper
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={className}
      margin={margin}
      position={position}
    >
      {icon ? (
        <>
          <Icon src={icon} />
        </>
      ) : null}
      {label}
    </Wrapper>
  );
};
const Icon = styled.img`
  height: 24px;
  margin-right: 8px;
`;
const Wrapper = styled.button<WrapperProps>`
  color: ${white};
  background: ${primary};
  font-weight: 700;
  border: none;
  font-size: 1rem;
  line-height: 1;
  padding: 1rem 1.5rem;
  border-radius: 6rem;
  display: flex;
  min-width: 8.75rem;
  text-align: center;
  justify-content: center;
  transition: background 0.2s;
  cursor: pointer;
  width: max-content;
  margin: ${(props) => props.margin || "0"};
  position: ${(props) => props.position || "relative"};
  align-items: center;
  transition: 0.2s all;
  &.grey {
    background: ${grey};
    color: ${black};
  }
  &.black {
    background: ${black};
    color: ${white};
  }
  &.big {
    padding: 1.25rem 1.5rem;
  }
  &:hover {
    transition: 0.2s all;
    background: ${black};
    color: ${white};
    &.black {
      background: ${primary};
    }
    &.grey {
      background: ${darkGrey};
      color: ${black};
    }
  }
`;
