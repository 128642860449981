import { forwardRef } from 'react';
import './style.css';
import { colors } from '../../../../styles/colors';
import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';

type Props = Types.InputProps;

export const Phone = forwardRef<any, Props>(({ value, name, error, onChange, onBlur, placeholder }, ref) => {
  return (
    <Wrapper>
      <PhoneInput
        placeholder="Enter phone number"
        isValid={!error}
        value={value}
        enableAreaCodes={true}
        country="lt"
        onlyCountries={['lv', 'lt', 'ee']}
        onChange={(value, data, event) => {
          onChange(value);
        }}
        onBlur={onBlur}
      />
    </Wrapper>
  );
});

const Wrapper = styled.div`
  width: 100%;
  font-family: 'Plus Jakarta Sans', sans-serif;

  .react-tel-input {
    font-size: 16px;
    position: relative;
    width: 100%;
  }

  .react-tel-input :disabled {
    cursor: not-allowed;
  }

  .react-tel-input .hide {
    display: none;
  }
  .react-tel-input .v-hide {
    visibility: hidden;
  }
  .react-tel-input .form-control {
    position: relative;
    font-size: 16px;
    letter-spacing: 0.01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 64px;
    margin-left: 0;
    background: ${colors.WHITE};
    border: 1px solid ${colors.G1};
    border-radius: 8px;
    line-height: 25px;
    height: 48px;
    width: 100%;
    outline: none;
  }
  .react-tel-input .form-control.invalid-number {
    color: ${colors.S5};
    border: 1px solid ${colors.S5};
  }
  .react-tel-input .form-control.invalid-number + .flag-dropdown {
    border: 1px solid ${colors.S5};
  }

  .react-tel-input .form-control:hover {
    border: 1px solid ${colors.G4};
  }
  .react-tel-input .form-control:hover + .flag-dropdown {
    border: 1px solid ${colors.G4};
  }
  .react-tel-input .form-control.invalid-number:hover {
    border: 1px solid ${colors.S5};
  }
  .react-tel-input .form-control.invalid-number:hover + .flag-dropdown {
    border: 1px solid ${colors.S5};
  }

  .react-tel-input .form-control:focus {
    border: 1px solid ${colors.P1};
  }
  .react-tel-input .form-control:focus + .flag-dropdown {
    border: 1px solid ${colors.P1};
  }
  .react-tel-input .form-control.invalid-number:focus {
    border: 1px solid ${colors.S5};
  }
  .react-tel-input .form-control.invalid-number:focus + .flag-dropdown {
    border: 1px solid ${colors.S5};
  }

  .react-tel-input .form-control:active {
    transform: scale(0.99);
  }

  .react-tel-input .flag-dropdown {
    position: absolute;
    height: 48px;
    width: 48px;
    top: 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border: 1px solid ${colors.G1};
  }
  .react-tel-input .flag-dropdown:hover {
    cursor: pointer;
    background-color: ${colors.WHITE};
    border: 1px solid ${colors.G4};
  }
  .react-tel-input .flag-dropdown:focus,
  .react-tel-input .flag-dropdown.open {
    cursor: pointer;
    background-color: ${colors.P4};
    border: 1px solid ${colors.P1};
  }
  .react-tel-input input[disabled] + .flag-dropdown:hover {
    cursor: default;
  }
  .react-tel-input input[disabled] + .flag-dropdown:hover .selected-flag {
    background-color: transparent;
  }
  .react-tel-input .selected-flag {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .react-tel-input .selected-flag .flag {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .react-tel-input .selected-flag .arrow {
    display: none;
  }
  .react-tel-input .selected-flag .arrow.up {
    display: none;
  }
  .react-tel-input .country-list {
    font-weight: 500;
    font-size: 16px;

    outline: none;
    z-index: 1;
    list-style: none;
    position: absolute;
    padding: 8px 8px 4px 8px;
    margin: 8px 0 0 -1px;
    background-color: ${colors.WHITE};
    border-radius: 8px;
    border: 1px solid ${colors.G1};
    width: 300px;
    max-height: 280px;
    overflow-y: scroll;
  }
  .react-tel-input .country-list .flag {
    display: inline-block;
  }
  .react-tel-input .country-list .divider {
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #ccc;
  }
  .react-tel-input .country-list .country {
    padding: 8px;
    margin-bottom: 4px;
    border-radius: 4px;
  }
  .react-tel-input .country-list .country .dial-code {
    color: ${colors.G4};
  }
  .react-tel-input .country-list .country:hover {
    background-color: ${colors.P4};
  }
  .react-tel-input .country-list .country.highlight {
    background-color: ${colors.P4};
  }
  .react-tel-input .country-list .flag {
    margin-right: 7px;
    margin-top: 2px;
  }
  .react-tel-input .country-list .country-name {
    margin-right: 6px;
  }
  .react-tel-input .country-list .search {
    position: sticky;
    top: 0;
    background-color: ${colors.WHITE};
  }
  .react-tel-input .country-list .search-emoji {
    font-size: 16px;
    position: absolute;
    top: 5px;
    left: 9px;
  }
  .react-tel-input .country-list .search-box {
    border: none;
    border-bottom: 1px solid ${colors.G1};
    width: 100%;
    font-size: 16px;
    line-height: 16px;
    padding: 5px 8px 5px 32px;
    outline: none;
  }
  .react-tel-input .country-list .no-entries-message {
    padding: 7px 10px 11px;
    opacity: 0.7;
  }
  .react-tel-input .invalid-number-message {
    position: absolute;
    z-index: 1;
    font-size: 13px;
    left: 46px;
    top: -8px;
    background: ${colors.WHITE};
    padding: 0 2px;
    color: ${colors.S5};
  }
  .react-tel-input .special-label {
    display: none;
    position: absolute;
    z-index: 1;
    font-size: 13px;
    left: 46px;
    top: -8px;
    background: ${colors.WHITE};
    padding: 0 2px;
    white-space: nowrap;
  }
`;
