import { useEffect, useMemo, useState } from 'react';
import Select, { StylesConfig, ThemeConfig, defaultTheme } from 'react-select';
import { Control, Controller } from 'react-hook-form';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import Environment from '../../environment';
import { Label, FullWidthContainer, SelectStyles } from '../../styles/elements';
import { DropdownIndicator } from './inputs/Select';
import styled from 'styled-components';
import { colors } from '../../styles/colors';
import { icons } from '../../utils/icons';

type FormValues = {
  service: Types.SelectOption;
  description: string;
  date: string;
  time: Types.SelectOption;
  address: string;
  id: string;
  email: string;
  phone: string;
};

type Props = {
  control: Control<FormValues, any>;
  register: any;
  id: string;
  label: string;
  name: string;
  formRegister: any;
  placeholder: string;
};

export const GeoCodingField = (props: Props) => {
  const { id, name, label, control, formRegister, placeholder, register } = props;
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
    apiKey: Environment.googleKey,
    debounce: 300,
  });

  let [autoAddress, setAutoAddress] = useState<{ value: any; label: any }[]>([]);

  /**
   * Process google address suggestions and pass them as the options for the select dropdown
   */
  useEffect(() => {
    if (placePredictions.length) {
      setAutoAddress(placePredictions.map((e) => ({ value: e.place_id, label: e.description })));
    }
  }, [placePredictions]);

  const addressStyle: StylesConfig = SelectStyles('', 'map_pin');

  return (
    <FullWidthContainer>
      {!!label && <Label>{label}</Label>}
      <Wrapper>
        <Controller
          control={control}
          name={name}
          id={id}
          type={'text'}
          {...register(name, formRegister)}
          ref={null}
          render={({ field }) => (
            <Select
              id={id}
              className={'form-control'}
              options={autoAddress}
              isLoading={isPlacePredictionsLoading}
              placeholder={placeholder}
              theme={customTheme}
              components={{
                DropdownIndicator,
              }}
              filterOption={(option) => true}
              onInputChange={(inputAddress) => getPlacePredictions({ input: inputAddress })}
              // @ts-ignore
              styles={addressStyle}
              {...field}
            />
          )}
        />
        <Icon src={icons.map_pin} />
      </Wrapper>
    </FullWidthContainer>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const customTheme: ThemeConfig = {
  borderRadius: 8,
  spacing: defaultTheme.spacing,
  colors: {
    ...defaultTheme.colors,
    primary: colors.P1,
    primary25: colors.P2,
    primary50: colors.P3,
    primary75: colors.P4,
  },
};

const Icon = styled.img`
  position: absolute;
  height: 24px;
  width: 24px;
  left: 16px;
`;
