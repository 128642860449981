import styled from "styled-components";
import { colors } from "../../styles/colors";
import { ReactComponent as XLogo } from "../../assets/icons/x.svg";

type Props = {
  show: boolean;
  setShow?: (show: boolean) => void;
  children: React.ReactNode;
};

export const Modal = ({ show, setShow, children }: Props) => {
  if (!show) {
    return null;
  }

  return (
    <Wrapper>
      <CenterInViewport>
        <Container>
          {setShow ? (
            <CloseButton onClick={() => setShow(false)}>
              <XLogo height={32} />
            </CloseButton>
          ) : null}
          {children}
        </Container>
      </CenterInViewport>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  z-index: 100;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: flex-start;

  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);

  background: #00000088;
`;

const CenterInViewport = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  position: -webkit-sticky;
  position: sticky;
  top: 0;
`;

const Container = styled.div`
  position: relative;
  border-radius: 8px;
  display: flex;
  width: 480px;
  padding: 64px 32px 32px 32px;
  background-color: ${colors.WHITE};
`;

const CloseButton = styled.button`
  position: absolute;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 32px;
  right: 32px;

  background: ${colors.G1};
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: none;

  &:hover {
    opacity: 0.8;
  }
`;
