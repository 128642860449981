import styled from "styled-components";
import { colors } from "../../styles/colors";
import { useTranslation } from "react-i18next";
import { Link as UnstyledLink } from "react-router-dom";

export const Agreement = () => {
  // https://github.com/i18next/react-i18next/issues/1417
  // @ts-ignore
  let { t } = useTranslation("translations");

  return (
    <Text>
      {`${t("call_center.agreement.by_bla_bla_bla")} `}
      <Link to="/terms-of-service">
        {t("call_center.agreement.terms_and_conditions")}
      </Link>
      {` ${t("call_center.agreement.and")} `}
      <Link to="/privacy-policy">
        {t("call_center.agreement.privacy_policy")}
      </Link>
      {`.`}
    </Text>
  );
};

const Text = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.G4};
  margin: 0 0 16px 0;
`;

const Link = styled(UnstyledLink)`
  color: ${colors.P1};
  &:visited {
    color: ${colors.P1};
  }
  &:hover,
  &:focus,
  &:active {
    color: ${colors.P3};
  }
`;
