import { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { mobile } from '../styles/breakpoints';
import { images } from '../utils/images';
import { FormHeader, Button, Agreement } from '../components/callCenter';
import { Input } from '../components/input';
import { Container } from '../components/wrappers/Container';
import { useTranslation } from 'react-i18next';
import { colors } from '../styles/colors';
import { SignInModal } from '../components/callCenter/SignInModal';
import { SuccessModal } from '../components/callCenter/SuccessModal';
import { useForm, Controller } from 'react-hook-form';
import { useStore } from '../hooks/useStore';
import { DevTool } from '@hookform/devtools';
import axios from 'axios';
import { LANGUAGE_MAPPING } from '../utils/constants';
import { useMutation } from '@tanstack/react-query';
import moment from 'moment';
import { toast } from 'react-toastify';
import Environment from '../environment';
import { GeoCodingField } from '../components/input/GeoCodeingField';
import { InputContainer, InputsContainer } from '../styles/elements';

type FormValues = {
  service: any;
  description: string;
  date: string;
  time: any;
  address: any;
  email: string;
  phone: string;
};

export const CallCenterRequest = () => {
  // https://github.com/i18next/react-i18next/issues/1417
  // @ts-ignore
  let { t, i18n } = useTranslation('translations');
  const { userData } = useStore();
  const token = userData?.accessToken;

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>();

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [flows, setFlows] = useState<Types.Flow[]>([]);

  const fetchFlows = async () => {
    const response = await axios({
      method: 'GET',
      url: `${Environment.apiUrl}/functions/categories/${Environment.categoryId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const flows = response.data?.category?.flows;

    if (flows.length > 0) {
      setFlows(flows);
    } else {
      throw new Error('No data available');
    }
  };

  useEffect(() => {
    if (!token) return;

    fetchFlows();
  }, [token]);

  const createJob = async (body: Types.JobBody): Promise<Array<Types.Category> | void> => {
    if (!userData) return;
    if (userData.integrations.length === 0) return;

    const integrationKey = userData.integrations[0].apiKey;
    if (integrationKey) {
      const response = await axios({
        method: 'POST',
        url: `${Environment.apiUrl}/api/integrations/jobs/installation`,
        headers: {
          Authorization: `Bearer ${integrationKey}`,
          locale: 'lt',
        },
        data: body,
      });

      return response.data?.categories;
    }

    throw new Error('User has no token attached');
  };

  /**
   * Clear form fields
   */
  const clearForm = () => {
    reset({
      service: null,
      description: '',
      date: '',
      time: null,
      address: null,
      phone: '',
      email: '',
    });
  };

  const postJob = useMutation(createJob, {
    onSuccess: (data) => {
      setShowSuccessModal(true);
      clearForm();

      setTimeout(() => {
        setShowSuccessModal(false);
      }, 5000);
    },
    onSettled: () => {
      setIsLoading(false);
    },
    onError: () => {
      toast.error(t('call_center.validation.something_went_wrong'), {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
  });

  const submitForm = handleSubmit(({ address, email, phone, date, time, description, service }) => {
    if (flows.length === 0) return;

    const flow = flows.find((flow) => flow.id === service.value);

    // Find minimum price in a list of city statistics
    const numberOfCities = flow?.cityStats?.length;
    let cityWithMinPrice = flow?.cityStats[0];
    if (cityWithMinPrice && numberOfCities && numberOfCities > 1) {
      cityWithMinPrice = flow?.cityStats?.reduce(
        (p, c) => (p.averageStartingPrice > c.averageStartingPrice ? p : c),
        cityWithMinPrice,
      );
    }
    const servicePrice = cityWithMinPrice?.averageStartingPrice ?? 1000;

    const body = {
      job: {
        address: address.label.trim(),
        dateTime: `${moment(new Date(date)).format('YYYY/MM/DD')} ${time.label}`,
        servicePrice: servicePrice || 2500,
        customer: {
          email: email.replace(/\s/g, ''),
          phone: phone.replace(/\s/g, ''),
        },
        flowId: service.value,
        description: description.trim(),
      },
    };

    setIsLoading(true);
    postJob.mutate(body);
  });

  const getImageLinkFromS3Object = (object?: Types.S3Object): string | undefined => {
    const bucket = object?.bucketName;
    const key = object?.assetKey;
    if (bucket && key) {
      return `${Environment.awsS3Url}/${bucket}/${key}`;
    }

    return undefined;
  };

  const flowSelectOptions = useMemo(() => {
    const result: Array<Types.SelectOption> = [];

    if (!flows || (flows?.length && flows.length <= 0)) {
      return result;
    }

    const language = i18n.language;
    let languageName = LANGUAGE_MAPPING['en'];
    // Check if language is a key of the language mapping, if
    // it is then we are going to use that language name
    if (language in LANGUAGE_MAPPING) {
      const cca2 = language as keyof typeof LANGUAGE_MAPPING;
      languageName = LANGUAGE_MAPPING[cca2];
    }

    flows.forEach((flow) => {
      try {
        let translation = flow.translationsVirtual.find(
          (translation) => translation.language.toLowerCase() === languageName.toLowerCase(),
        );
        // If there are no translations, than take the english one
        if (!translation) {
          translation = flow.translationsVirtual.find((translation) => translation.language.toLowerCase() === 'en');
        }
        // And if there is no english translation, than take the first one
        if (!translation) {
          translation = flow.translationsVirtual[0];
        }

        const id = flow.id ?? flow._id ?? undefined;

        if (translation && id) {
          result.push({
            value: id,
            label: translation.translationValue,
          });
        }
      } catch (e) {
        console.warn(e);
      }
    });

    return result;
  }, [flows, i18n.language]);

  return (
    <Base>
      <Container zIndex="1">
        <Form onSubmit={submitForm}>
          <FormHeader
            text={t('call_center.form.title')}
            image={getImageLinkFromS3Object(userData?.partner?.displayImage)}
          />
          <Controller
            control={control}
            name="service"
            rules={{
              required: t('call_center.validation.required'),
            }}
            render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
              <Input
                type="select"
                options={flowSelectOptions}
                label={t('call_center.form.service')}
                placeholder={t('call_center.form.service_placeholder')}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                name={name}
                value={value}
                error={error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="description"
            render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
              <Input
                type="multiline"
                label={t('call_center.form.description')}
                placeholder={t('call_center.form.description_placeholder')}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                name={name}
                value={value}
                error={error?.message}
              />
            )}
          />

          <InputsContainer>
            <InputContainer>
              <Controller
                control={control}
                name="date"
                rules={{
                  required: t('call_center.validation.required'),
                }}
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
                  <Input
                    type="date"
                    label={t('call_center.form.date')}
                    placeholder={t('call_center.form.date_placeholder')}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    name={name}
                    value={value}
                    error={error?.message}
                  />
                )}
              />
            </InputContainer>

            <InputContainer>
              <Controller
                control={control}
                name="time"
                rules={{
                  required: t('call_center.validation.required'),
                }}
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
                  <Input
                    type="time"
                    label={t('call_center.form.time')}
                    placeholder={t('call_center.form.time_placeholder')}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    name={name}
                    value={value}
                    error={error?.message}
                  />
                )}
              />
            </InputContainer>
          </InputsContainer>

          <GeoCodingField
            id="address"
            name="address"
            // @ts-ignore
            control={control}
            register={register}
            label={t('call_center.form.address')}
            formRegister={{
              required: t('call_center.validation.required'),
            }}
            placeholder={t('call_center.form.address_placeholder')}
          />

          <InputsContainer>
            <InputContainer>
              <Input
                type="text"
                label={t('call_center.form.email')}
                error={errors.email?.message}
                placeholder={t('call_center.form.email_placeholder')}
                {...register('email', {
                  required: t('call_center.validation.required'),
                  minLength: {
                    value: 6,
                    message: t('call_center.validation.min_length'),
                  },
                  maxLength: {
                    value: 128,
                    message: t('call_center.validation.max_length'),
                  },
                  pattern: {
                    value: /^\S+@\S+\.+\S+$/i,
                    message: t('call_center.validation.invalid_email'),
                  },
                })}
              />
            </InputContainer>
            <InputContainer>
              <Controller
                control={control}
                name="phone"
                rules={{
                  required: t('call_center.validation.required'),
                }}
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
                  <Input
                    type="phone"
                    label={t('call_center.form.phone')}
                    placeholder={t('call_center.form.phone_placeholder')}
                    onBlur={onBlur}
                    onChange={onChange}
                    name={'phone'}
                    value={value}
                    error={error?.message}
                  />
                )}
              />
            </InputContainer>
          </InputsContainer>
          <Agreement />

          <Button type="submit" loading={isLoading} text={t('call_center.request_service')} />
        </Form>
      </Container>

      <ImageWrapper>
        <Image src={images.requestImage} />
      </ImageWrapper>

      <SignInModal show={!userData?.accessToken} />
      <SuccessModal show={showSuccessModal} setShow={setShowSuccessModal} />
      {process.env.NODE_ENV === 'development' && <DevTool control={control} />}
    </Base>
  );
};

const Base = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 64px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 600px;
  padding: 32px;
  background: ${colors.WHITE};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04), 0px 8px 40px rgba(77, 77, 77, 0.1);
  border-radius: 8px;

  @media ${mobile} {
    width: 100%;
    box-shadow: none;
    border-radius: none;
    padding: 16px;
  }
`;
const ImageWrapper = styled.div`
  position: absolute;
  right: 0;
  @media ${mobile} {
    display: none;
  }
`;
const Image = styled.img`
  height: 860px;
  object-fit: contain;
  object-position: right 0%;
`;
