import create from "zustand";
import { devtools, persist } from "zustand/middleware";

type State = {
  userData: Types.UserData | null;
  setUserData: (userData: Types.UserData | null) => void;
};

export const useStore = create<State>()(
  devtools(
    persist((set) => ({
      userData: null,
      setUserData: (userData) => set({ userData }),
    }))
  )
);
