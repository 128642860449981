import { forwardRef } from 'react';
import styled from 'styled-components';
import { colors } from '../../../styles/colors';
import { useTranslation } from 'react-i18next';
import { icons } from '../../../utils/icons';

type Props = Types.InputProps;

export const Multiline = forwardRef<HTMLTextAreaElement, Props>(
  ({ value, name, error, onChange, onBlur, placeholder }, ref) => {
    return (
      <Input
        ref={ref}
        value={value}
        rows={3}
        name={name}
        className={error ? 'error' : undefined}
        placeholder={placeholder ? placeholder : undefined}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  },
);

const Input = styled.textarea`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${colors.G1};
  border-radius: 8px;
  padding: 10px 16px;
  overflow: hidden;
  font-family: 'Plus Jakarta Sans';
  line-height: 24px;
  resize: none;

  &:hover {
    border: 1px solid ${colors.G4};
  }

  &:focus {
    border: 1px solid ${colors.P1};
    outline: none;
  }

  &:hover + .error {
    border: 1px solid ${colors.S5};
  }

  &:focus + .error {
    outline: 1px solid ${colors.S5};
  }

  .error {
    color: ${colors.S5};
  }
`;
