import { useEffect, useState } from "react";
import i18next from "i18next";
import { Helmet } from "react-helmet";

const RefLangBlock = () => {
  const languages = Object.keys(i18next.services.resourceStore.data);
  const [pathname, setPathname] = useState("");

  useEffect(() => {
    let stripped = window.location.pathname;
    for (let language of languages) {
      stripped = stripped.replace(`/${language}/`, "/");
    }
    setPathname(stripped);
  }, [languages]);

  return (
    <Helmet>
      <link
        rel="alternate"
        hreflang="x-default"
        href={`${window.location.host}${pathname}`}
      />
      {languages.map((lang) => {
        const pathArr = [window.location.host];
        if (lang !== "en") pathArr.push(lang);
        pathArr.push(pathname);
        const pathString = pathArr.join("/").replace("//", "/");

        return (
          <link rel="alternate" key={lang} hreflang={lang} href={pathString} />
        );
      })}
    </Helmet>
  );
};

export default RefLangBlock;
