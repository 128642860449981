import { useMemo } from "react";
import { Select } from "./Select";
import { useTranslation } from "react-i18next";
import { icons } from "../../../utils/icons";

type Props = Types.InputProps;

const HOURS_FROM = 8;
const HOURS_TO = 21;

export const Time = ({ ...props }: Props) => {
  // https://github.com/i18next/react-i18next/issues/1417
  // @ts-ignore
  let { t } = useTranslation("translations");

  const hours = useMemo(() => {
    const availableHours = [];
    const hourObject: {
      value?: string; // "12:00 - 13:00"
      label?: string; // "12:00"
    } = {};

    for (let i = HOURS_FROM; i < HOURS_TO; i++) {
      hourObject.value = `${String(i).padStart(2, "0")}:00 - ${String(
        i + 1
      ).padStart(2, "0")}:00`;
      hourObject.label = `${String(i).padStart(2, "0")}:00`;
      availableHours.push({ ...hourObject });
    }

    return availableHours;
  }, []);

  return (
    <Select
      options={hours}
      icon={icons.clock}
      placeholder={t("call_center.form.time_placeholder")}
      {...props}
    />
  );
};
