import i18n from "i18next";
import enMainTranslation from "./translations/en.json";
import ltMainTranslation from "./translations/lt.json";
import lvMainTranslation from "./translations/lv.json";
import eeMainTranslation from "./translations/ee.json";
import ruMainTranslation from "./translations/ru.json";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

export const resources = {
  en: {
    translations: enMainTranslation,
  },
  lt: {
    translations: ltMainTranslation,
  },
  lv: {
    translations: lvMainTranslation,
  },
  ee: {
    translations: eeMainTranslation,
  },
  ru: {
    translations: ruMainTranslation,
  },
};

const options = {
  order: ["path", "localStorage"],
  lookupFromPathIndex: 0,
  lookupLocalStorage: "locale",
};

i18n.on("languageChanged", (lng) => {
  const possibleLangs = ["lt", "ee", "en", "lv", "ru"];
  const paths = window.location.pathname.split("/");
  const filteredPaths: any = paths.filter(
    (path) => path !== "" && possibleLangs.indexOf(path) === -1
  );

  const fallback = i18n.options.fallbackLng as string[];
  if (lng !== fallback[0]) {
    filteredPaths.unshift(lng);
  }

  const newPath = `/${filteredPaths.join("/")}`.replace("//", "/");
  if (window.location.pathname !== newPath) {
    const newUrl = (window.location.pathname = newPath);
    window.location.replace(newUrl);
  }
});

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    supportedLngs: ["lv", "lt", "ee", "ru", "en"],
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    detection: options,
    interpolation: {
      escapeValue: false,
    },
    resources,
  });

export default i18n;
