// If anyone is want to be a smart ass and ask why I put "env" variables in constants file,
// well that's is because if you put in the .env file it will still be bundle together with
// the code and the end-user will be able to fish it out. So I put them here. You can read
// more about this here: https://create-react-app.dev/docs/adding-custom-environment-variables/
// ...
// Reply from the "smart-ass": if you put them in the .env file, then they will get proper values based on the environment
// without the conditional checks in the project to check whether "constant" of dev or prod env should be used...
// Moved environment vars to .env where they should be placed... :facepalm:

const LANGUAGE_MAPPING = {
  en: 'English',
  lv: 'Latvian',
  ru: 'Russian',
  ee: 'Estonian',
  lt: 'Lithuanian',
};

export { LANGUAGE_MAPPING };
