import { useMemo } from 'react';
import styled from 'styled-components';
import { colors } from '../../../styles/colors';
import SelectBase, {
  ThemeConfig,
  StylesConfig,
  defaultTheme,
  OptionsOrGroups,
  GroupBase,
  DropdownIndicatorProps,
} from 'react-select';
import { SelectStyles } from '../../../styles/elements';

type Props = Types.InputProps & {
  icon?: string;
  options?: OptionsOrGroups<unknown, GroupBase<unknown>>;
};

const IndicatorSeparator = () => null;

export const CaretDown = ({ stroke = '#F3F3F6' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5 9.00006L12 16.5001L4.5 9.00006"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DropdownIndicator = ({
  /** The children to be rendered inside the indicator. */
  children,
  /** Props that will be passed on to the children. */
  innerProps,
  /** The focused state of the select. */
  isFocused,
  isDisabled,
}: DropdownIndicatorProps) => <div {...innerProps}>{isFocused ? <CaretDown stroke={colors.P1} /> : <CaretDown />}</div>;

export const Select = ({ icon, options, placeholder, name, onBlur, onChange, error, value }: Props) => {
  const customStyles: StylesConfig = useMemo(() => SelectStyles(error, icon), [icon, error]);

  return (
    <Wrapper>
      <SelectBase
        name={name}
        options={options}
        className={'selector-base'}
        theme={customTheme}
        styles={customStyles}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        components={{
          IndicatorSeparator,
          DropdownIndicator,
        }}
      />
      {icon && <Icon src={icon} />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Icon = styled.img`
  position: absolute;
  height: 24px;
  width: 24px;
  left: 16px;
`;

const customTheme: ThemeConfig = {
  borderRadius: 8,
  spacing: defaultTheme.spacing,
  colors: {
    ...defaultTheme.colors,
    primary: colors.P1,
    primary25: colors.P2,
    primary50: colors.P3,
    primary75: colors.P4,
  },
};
