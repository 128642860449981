import { useEffect } from "react";
import { isAndroid, isIOS } from "react-device-detect";

export const Get = () => {
  useEffect(() => {
    const link = isAndroid
      ? "https://play.google.com/store/apps/details?id=com.partly"
      : isIOS
      ? "https://apps.apple.com/us/app/id1449245749"
      : "https://hoomtask.com";

    window.location.replace(link);
  }, []);

  return null;
};
