import styled from 'styled-components';
import { colors } from './colors';

const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  column-gap: 16px;
`;

const FullWidthContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  column-gap: 16px;
  margin-bottom: 16px;
`;

const InputContainer = styled.div`
  display: flex;
  flex: 1;
`;

const Label = styled.label`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.BLACK};
  margin: 0 0 8px 0;
`;

const SelectStyles = (error: any, icon?: string) => {
  return {
    container: (base: any) => ({
      ...base,
      height: '100%',
      width: '100%',
    }),
    control: (base: any, state: any) => ({
      ...base,

      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      border: `1px solid ${!!error ? colors.S5 : colors.G1}`,
      padding: `0 14px 0 ${icon ? '54px' : '14px'}`,
      cursor: 'pointer',
      fontWeight: 400,
      fontSize: '16px',
      '&:hover': {
        border: state.isFocused ? 'none' : `1px solid ${colors.G4}`,
      },
      '&:active': {
        border: `1px solid ${colors.G4}`,
      },
    }),
    valueContainer: (base: any) => ({
      ...base,
      padding: 0,
    }),
    indicatorSeparator: () => ({}),
    dropdownIndicator: (base: any) => ({
      ...base,
    }),
    placeholder: (base: any) => ({
      ...base,
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '24px',
      color: colors.G4,
    }),
    menu: (base: any) => ({
      ...base,
      backgroundColor: colors.WHITE,
      borderRadius: 8,
      padding: 8,
      paddingBottom: 4,
      boxShadow: 'none',
      border: `1px solid ${colors.G1}`,
    }),
    option: (base: any, props: any) => ({
      ...base,
      borderRadius: 4,
      marginBottom: 4,
      backgroundColor: props.isFocused ? colors.P4 : props.isSelected ? colors.P1 : colors.WHITE,
      cursor: 'pointer',
    }),
  };
};

export { Label, InputContainer, InputsContainer, FullWidthContainer, SelectStyles };
