import {
  Hero,
  About,
  Order,
  Random,
  Geography,
  Download,
  Providers,
  Services,
} from "../components";

export const Home = () => {
  return (
    <>
      <Hero />
      <Services />
      <About />
      <Order />
      <Random />
      <Geography />
      <Download />
      <Providers />
    </>
  );
};
