import IEnvironment from './IEnvironment';

/**
 * Format ENV variable from any possible truthy value to boolean true
 * @param envVal
 */
const parseBoolean = (envVal: string | number): boolean => {
  return envVal === 'true' || envVal === 'TRUE' || envVal === '1' || envVal === 1;
};

/**
 * Typescript Based Environment File that gets populated by .env file in each environment
 */
const Environment: IEnvironment = {
  apiUrl: process.env.REACT_APP_API_URL || '',
  googleKey: process.env.REACT_APP_GOOGLE_KEY || '',
  awsS3Url: process.env.REACT_APP_AWS_S3_URL || '',
  categoryId: process.env.REACT_APP_CATEGORY_ID || '',
  env: process.env.NODE_ENV,
};

export default Environment;
