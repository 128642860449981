export const colors = {
  P1: "#4258CF",
  P2: "#566DE8",
  P3: "#A9B7FF",
  P4: "#E1E6FF",
  P5: "#F5F6FF",

  S1: "#01BE83",
  S2: "#CCF2E6",
  S3: "#FDC532",
  S4: "#FCE5BB",
  S5: "#FD3232",
  S6: "#FCBBBB",

  G1: "#F3F3F6",
  G2: "#DEDEE0",
  G3: "#B6B6B8",
  G4: "#8D8E8F",
  G5: "#515152",

  WHITE: "#FFFFFF",
  BLACK: "#1E1E20",
  REAL_BLACK: "#000000",
  FACEBOOK: "#1877f2",
  HOOM_TASK: "#4159CE",

  // old colors
  black: "#201E1F",
  primary: "#4258CF",
  secondary: "#55BB87",
  lightGreen: "#E1FFF0",
  lightBlue: "#DBE1FF",
  orange: "#FFB43F",
  grey: "#ebebeb",
  white: "#FFFFFF",
  border: "#EAEAEA",
  darkGrey: "#d4d4d4",
};

// exporting old colors to be compatible with the old code
export const {
  black,
  primary,
  secondary,
  lightGreen,
  lightBlue,
  orange,
  grey,
  white,
  border,
  darkGrey,
} = colors;
