import { forwardRef } from 'react';
import styled from 'styled-components';
import { colors } from '../../styles/colors';
import { DateField } from './inputs/Date';
import { Time } from './inputs/Time';
import { Select } from './inputs/Select';
import { MaterialText } from './inputs/MaterialText';
import { Multiline } from './inputs/Multiline';
import { Phone } from './inputs/Phone';
import { Text } from './inputs/Text';
import { Label } from '../../styles/elements';

type Props = {
  type: Types.InputType;
  label: string;
  textType?: Types.InputTextType;
  styling?: Types.InputStyle;
  icon?: string;
  options?: Array<Types.SelectOption>;
  // Props for inputs
  error?: string;
  value?: any;
  name: string;
  placeholder?: string;
  onChange: React.ChangeEventHandler<any>;
  onBlur: React.FocusEventHandler<any>;
};

export const Input = forwardRef<any, Props>(
  ({ type, label, styling, textType = 'text', error, options, icon, ...inputProps }, ref) => {
    const renderInput = () => {
      switch (type) {
        case 'date':
          return <DateField error={error} {...inputProps} />;
        case 'time':
          return <Time error={error} {...inputProps} />;
        case 'phone':
          return <Phone ref={ref} error={error} {...inputProps} />;
        case 'multiline':
          return <Multiline ref={ref} error={error} {...inputProps} />;
        case 'text':
          return <Text ref={ref} error={error} icon={icon} {...inputProps} />;
        case 'select':
          return <Select options={options} icon={icon} error={error} {...inputProps} />;
        default:
          return (
            <InputPlaceholder>
              <InputText>Select something</InputText>
            </InputPlaceholder>
          );
      }
    };

    const renderMaterialInput = () => {
      switch (type) {
        case 'date':
        case 'time':
        case 'phone':
        case 'select':
        case 'multiline':
        case 'text':
          return <MaterialText ref={ref} label={label} type={textType} error={error} {...inputProps} />;
        default:
          return null;
      }
    };

    if (styling === 'material') {
      return renderMaterialInput();
    }

    return (
      <Container>
        <Label>{label}</Label>
        {renderInput()}
        {error ? <Error>{error}</Error> : null}
      </Container>
    );
  },
);

const Container = styled.div`
  textarea,
  input {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Plus Jakarta Sans', sans-serif;
  }
  textarea::-webkit-input-placeholder,
  input::-webkit-input-placeholder {
    font-family: 'Plus Jakarta Sans', sans-serif;
  }
  textarea:-ms-input-placeholder,
  input:-ms-input-placeholder {
    font-family: 'Plus Jakarta Sans', sans-serif;
  }
  textarea:-moz-placeholder,
  input:-moz-placeholder {
    font-family: 'Plus Jakarta Sans', sans-serif;
  }
  textarea::-moz-placeholder,
  input::-moz-placeholder {
    font-family: 'Plus Jakarta Sans', sans-serif;
  }
  textarea.error,
  input.error {
    border: 1px solid ${colors.S5};
  }
  textarea.error:focus,
  input.error:focus {
    border: 2px solid ${colors.S5};
    outline: none;
  }

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 0 16px 0;
`;

const InputPlaceholder = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${colors.G1};
  padding: 0 16px;
  border-radius: 8px;
`;
const InputText = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.G4};
`;
const Error = styled.div`
  font-weight: 400;
  font-size: 12px;
  height: 16px;
  line-height: 16px;
  color: ${colors.S5};
  margin: 4px 0 0 0;
`;
