import styled from "styled-components";
import { tablet } from "../../styles/breakpoints";

type Props = {
  padding?: string;
  zIndex?: string;
  overflow?: string;
  position?: string;
};

export const Container = styled.div<Props>`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: ${(props) => props.padding || "0"};
  z-index: ${(props) => props.zIndex || "auto"};
  overflow: ${(props) => props.overflow || "initial"};
  position: ${(props) => props.position || "initial"};

  @media ${tablet} {
    padding: ${(props) => props.padding || "0"};
  }
`;
