import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Container, Button } from '..';
import { colors } from '../../styles/colors';
import { mobile, tablet } from '../../styles/breakpoints';
import { icons } from '../../utils/icons';
import { useTranslation } from 'react-i18next';
import { setLanguage as setLang } from '../../utils/language';
import i18next from 'i18next';
import CookieConsent from 'react-cookie-consent';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../hooks/useStore';
import { ReactComponent as SignOutIcon } from '../../assets/icons/sign_out.svg';

export const Header = () => {
  // https://github.com/i18next/react-i18next/issues/1417
  // @ts-ignore
  const { t, i18n } = useTranslation('translations');
  const locale = i18next.language;
  const navigate = useNavigate();
  const userData = useStore((state) => state.userData);

  const languageOptions = [
    { value: 'en', label: 'English' },
    { value: 'lt', label: 'Lietuvių' },
    { value: 'lv', label: 'Latviešu' },
  ];

  const setLanguage = (lang: any) => {
    setLang(lang);
    i18n.changeLanguage(lang);
  };

  return (
    <HeaderWrapper>
      <CookieConsent>
        <CookieText>
          We use cookies to ensure that we offer you the best experience while browsing our website. Check our{' '}
          <a href="/cookie-policy">Cookie Policy</a> for more information.
        </CookieText>
      </CookieConsent>

      <Container>
        <Flex>
          <Link to="/">
            <Logo src={icons.hoomtask_logo} />
          </Link>

          <HeaderRightWrapper>
            <LanguageSelect
              name="language"
              id="language"
              defaultValue={locale}
              onChange={(e) => setLanguage(e.target.value)}
            >
              {languageOptions.map((lang) => {
                return (
                  <LanguageOption key={lang.value} value={lang.value}>
                    {lang.label}
                  </LanguageOption>
                );
              })}
            </LanguageSelect>
            {userData?.accessToken ? (
              <SignOutButton
                onClick={() => {
                  useStore.persist.clearStorage();
                  navigate(0);
                }}
              >
                <SignOutIcon />
                {t('header.sign_out')}
              </SignOutButton>
            ) : (
              <ButtonsWrapper>
                <Button
                  onClick={() => (window.location.href = 'https://partner.hoomtask.com/onboarding')}
                  label={t('actions.register_as_provider')}
                />
              </ButtonsWrapper>
            )}
          </HeaderRightWrapper>
        </Flex>
      </Container>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);

  @media ${tablet} {
    //box-shadow: none;
    padding: 0 32px;
  }
`;
const Flex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const HeaderRightWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 16px;
`;
const LanguageSelect = styled.select`
  background-color: transparent;
  border: 1px solid ${colors.WHITE};
  color: ${colors.BLACK};
`;
const LanguageOption = styled.option`
  background: rgba(200, 200, 200, 0.3);
  border: 1px solid ${colors.WHITE};
`;
const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  @media ${mobile} {
    display: none;
  }
`;
const Logo = styled.img`
  height: 28px;
  @media ${mobile} {
    height: 24px;
  }
`;
const CookieText = styled.div`
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 15px;
  color: ${colors.BLACK};
  line-height: 1.6;
`;
const SignOutButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  height: 48px;
  border-radius: 8px;
  border: none;
  padding: 8px;
  margin: 0;
  background: transparent;
  cursor: pointer;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;

  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
  color: ${colors.S5};

  &:hover,
  &:focus {
    opacity: 0.8;
    outline: transparent;
  }

  &:focus {
    outline: transparent;
  }

  &:active {
    transform: scale(0.99);
  }
`;
